<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Electrochemistry Experiment: Results - Part B</h2>

      <p class="mb-5">
        a) Fill in the information in the data table below summarizing your results from Part B of
        the experiment. <b>Please express the percent error value to 3 significant figures.</b>
      </p>

      <v-simple-table>
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\textbf{Variable}$" />
            </td>
            <td style="text-align: center">
              <stemble-latex content="$\textbf{Value}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{i) V}_\ce{CuSO4}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 1}$" />
              <stemble-latex content="$\text{ (mL)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.volCuSO41"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\ce{ii) [CuSO4]}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 1}$" />
              <stemble-latex content="$\text{ (M)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.concFlask1"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{iii) V}_\ce{CuSO4,dil}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 2}$" />
              <stemble-latex content="$\text{ (mL)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.volCuSO42"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\ce{iv) [CuSO4]}$" />
              <stemble-latex content="$\text{ in Vol}$" />
              <stemble-latex content="$\text{ Flask 2}$" />
              <stemble-latex content="$\text{ (M)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.concFlask2"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{v) E}_\text{cell,obs}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.EcellObs"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{vi) E}_\text{cell,theor}$" />
              <stemble-latex content="$\text{ (V)}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.EcellTheoretical"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{vii) }\%\,\text{Error}$" />
            </td>
            <td class="centered-input py-2 my-2">
              <calculation-input
                v-model="inputs.pctError"
                :show-scientific-notation="false"
                :disabled="!allowEditing"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2 mt-5">
        b) Please upload your cell diagram AND your data sheet for this part of the experiment using
        the upload field below.
      </p>

      <p class="mb-3">
        <v-file-input v-model="attachments" multiple accept="application/pdf" />
      </p>

      <p class="mb-1" style="font-weight: bold">
        Have multiple files to upload? Click
        <a
          href="https://support.stemble.ca/attach-multiple-files "
          rel="noopener noreferrer"
          target="_blank"
        >
          here
        </a>
        to find out how.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';
import STextarea from '@/common/components/STextareaShort.vue';

export default {
  name: 'ChemMcMA5_ResultsB',
  components: {
    STextarea,
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        volCuSO41: null,
        concFlask1: null,
        volCuSO42: null,
        concFlask2: null,
        EcellObs: null,
        EcellTheoretical: null,
        pctError: null,
      },
      attachments: [],
    };
  },
};
</script>
<style scoped></style>
